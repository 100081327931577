var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('CategoriesSearch',{attrs:{"clearable":""},on:{"input":function($event){return _vm.setCategoryId($event)}}})],1),_c('v-col',[_c('BrandsSearch',{attrs:{"clearable":""},on:{"input":function($event){return _vm.setBrandId($event)}}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Id Compra"},on:{"input":function($event){return _vm.setPurchaseId($event)}},model:{value:(_vm.params.purchase_id),callback:function ($$v) {_vm.$set(_vm.params, "purchase_id", $$v)},expression:"params.purchase_id"}})],1),_c('v-col',[_c('CompanySelect',{attrs:{"item-value":function (item) { return item.id; },"clearable":""},on:{"change":function($event){return _vm.select()}},model:{value:(_vm.params.company_id),callback:function ($$v) {_vm.$set(_vm.params, "company_id", $$v)},expression:"params.company_id"}})],1)],1),_c('v-row',[_c('v-col',[_c('SupplierSearch',{attrs:{"clearable":""},on:{"input":function($event){return _vm.setSupplierId($event)}}})],1),_c('v-col',{staticClass:"d-flex justify-space-between"},[_c('v-btn',{staticClass:"mr-4",attrs:{"color":"primary"},on:{"click":function($event){return _vm.select(1)}}},[_vm._v("Buscar")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.handleExport()}}},[_vm._v("Exportar")])],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"uniqueKey","hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.code)+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.brand",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.brand ? item.brand.name : "")+" ")]}},{key:"item.company_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.company_name)+" ")]}},{key:"item.available_stock_sum",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(" "+_vm._s(Math.abs(item.available_stock_sum))+" ")])]}},{key:"item.physic_stock_sum",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(" "+_vm._s(Math.abs(item.physic_stock_sum))+" ")])]}},{key:"item.minimum_stock",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(" "+_vm._s(Math.abs(item.minimum_stock))+" ")])]}},{key:"item.maximum_stock",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(" "+_vm._s(Math.abs(item.maximum_stock))+" ")])]}},{key:"item.packaging_quantity",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(" "+_vm._s(Math.abs(item.packaging_quantity))+" ")])]}},{key:"item.units_needed",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(" "+_vm._s(Math.abs(item.units_needed))+" un ")])]}}],null,true)}),_c('div',{staticClass:"text-center pt-2"},[_c('app-pagination',{attrs:{"data":_vm.purchases},on:{"click":function($event){return _vm.select($event)}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }