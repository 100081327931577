<template>
  <div>
    <v-row>
      <!-- <v-col>
        <ClassificacaoFiscalSelect v-model="params.classificacao_fiscal" />
      </v-col> -->
      <v-col>
        <CategoriesSearch @input="setCategoryId($event)" clearable />
      </v-col>
      <v-col>
        <BrandsSearch @input="setBrandId($event)" clearable />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field v-model="params.purchase_id" @input="setPurchaseId($event)" label="Id Compra" />
      </v-col>
      <v-col>
        <CompanySelect :item-value="(item) => item.id" v-model="params.company_id" @change="select()" clearable />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <SupplierSearch @input="setSupplierId($event)" clearable />
      </v-col>
      <v-col class="d-flex justify-space-between">
        <v-btn color="primary" class="mr-4" @click="select(1)">Buscar</v-btn>
        <v-btn color="primary" @click="handleExport()">Exportar</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table :headers="headers" :items="items" item-key="uniqueKey" hide-default-footer :items-per-page="-1">
          <template v-slot:[`item.code`]="{ item }">
            {{ item.code }}
          </template>
          <template v-slot:[`item.name`]="{ item }">
            {{ item.name }}
          </template>
          <template v-slot:[`item.brand`]="{ item }">
            {{ item.brand ? item.brand.name : "" }}
          </template>
          <template v-slot:[`item.company_name`]="{ item }">
            {{ item.company_name }}
          </template>
          <template v-slot:[`item.available_stock_sum`]="{ item }">
            <div class="text-end">
              {{ Math.abs(item.available_stock_sum) }}
            </div>
          </template>
          <template v-slot:[`item.physic_stock_sum`]="{ item }">
            <div class="text-end">
              {{ Math.abs(item.physic_stock_sum) }}
            </div>
          </template>
          <template v-slot:[`item.minimum_stock`]="{ item }">
            <div class="text-end">
              {{ Math.abs(item.minimum_stock) }}
            </div>
          </template>
          <template v-slot:[`item.maximum_stock`]="{ item }">
            <div class="text-end">
              {{ Math.abs(item.maximum_stock) }}
            </div>
          </template>
          <template v-slot:[`item.packaging_quantity`]="{ item }">
            <div class="text-end">
              {{ Math.abs(item.packaging_quantity) }}
            </div>
          </template>
          <template v-slot:[`item.units_needed`]="{ item }">
            <div class="text-end">
              {{ Math.abs(item.units_needed) }} un
            </div>
          </template>

        </v-data-table>
        <div class="text-center pt-2">
          <app-pagination @click="select($event)" :data="purchases" />

        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ClassificacaoFiscalSelect from "@/components/product/ui/ClassificacaoFiscalSelect";
import BrandsSearch from "@/components/brands/ui/BrandsSearch.vue";
import SupplierSearch from "@/components/suppliers/ui/SupplierSearch.vue";
import CategoriesSearch from "@/components/categories/ui/CategoriesSearch.vue";
import CompanySelect from "@/components/company/ui/CompanySelect.vue";


export default {
  components: {
    ClassificacaoFiscalSelect,
    BrandsSearch,
    SupplierSearch,
    CompanySelect,
    CategoriesSearch,
  },

  data: () => {
    return {
      purchases: {
        data: []
      },

      params: {
        page: 1,
        company_id: null,
        category_id: null,
        brand_id: null,
        supplier_id: null,
        purchase_id: null,
      },

      date: new Date().toISOString().slice(0, 10),

      headers: [
        { text: "Código", width: "5%", align: "start", value: "code" },
        { text: "Nome", value: "name" },
        { text: "Marca", value: "brand" },
        { text: "Empresa", value: "company_name" },
        { text: "Estoque disponível", width: "10%", align: "end", value: "available_stock_sum" },
        { text: "Estoque físico", width: "10%", align: "end", value: "physic_stock_sum" },
        { text: "Estoque mínimo", width: "10%", align: "end", value: "minimum_stock" },
        { text: "Estoque máximo", width: "10%", align: "end", value: "maximum_stock" },
        { text: "Quantidade de embalagem", width: "10%", align: "end", value: "packaging_quantity" },
        { text: "Quantidade de pedido", width: "10%", align: "end", value: "units_needed" }
      ],
    };
  },

  computed: {
    items() {
      return this.purchases.data.map((item, index) => {
        return { ...item, uniqueKey: index + 1 }
      })
    }
  },

  methods: {

    async select(page) {
      this.params.page = page

      await this.$http
        .index("product/product-suggestion", this.params).then((response) => {
          this.purchases = response.purchases
        })
        .catch((error) => { });
    },

    setBrandId(brand) {
      this.params.brand_id = brand && brand.id ? brand.id : null;
      this.select();
    },

    setSupplierId(supplier) {
      this.params.supplier_id = supplier && supplier.id ? supplier.id : null;
      this.select();
    },

    setCategoryId(category) {
      this.params.category_id = category && category.id ? category.id : null;
      this.select()
    },

    setCompanyId(company) {
      this.params.company_id = company.id || null;
    },

    setPurchaseId(purchase) {
      this.params.purchase_id = purchase;
      this.select()
    },

    handleExport() {

      this.$http
        .download("product/product-suggestion", this.params)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Produtos_${this.params.company_id}_${this.date}.xlsx`
          );
          document.body.appendChild(link);
          link.click();

        })
        .catch((error) => {
          console.log(error);

        });
    },


  }
}
</script>

<style lang="scss" scoped></style>